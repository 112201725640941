/*
 * @Date: 2022-07-04 19:58:49
 * @descriotion: 社保-设置
 */
import { INSURANCE } from '../api'
import { METHOD, request } from '@/utils/request'

// 险金方案

// 获取内置方案模板
export async function getSchemeTemplateListApi(params) {
  return request(`${INSURANCE}/builtinScheme/getSchemeTemplateList`, METHOD.GET, params)
}
// 获取内置方案数据（选择模板时使用）
export async function getSchemeDataListBySchemeIdApi(params) {
  return request(`${INSURANCE}/builtinScheme/getSchemeDataListBySchemeId`, METHOD.GET, params)
}
// 查询租户方案列表
export async function getSchemeListApi(params) {
  return request(`${INSURANCE}/scheme/getSchemeList`, METHOD.GET, params)
}
// 加载租户方案详情
export async function getSchemeApi(params) {
  return request(`${INSURANCE}/scheme/getScheme`, METHOD.GET, params)
}
// 新增租户方案
export async function createSchemeApi(params) {
  return request(`${INSURANCE}/scheme/createScheme`, METHOD.POST, params)
}
// 调整租户方案
export async function updateSchemeApi(params) {
  return request(`${INSURANCE}/scheme/updateScheme`, METHOD.POST, params)
}
// 删除租户方案
export async function delSchemeApi(params) {
  return request(`${INSURANCE}/scheme/delScheme`, METHOD.POST, params)
}
// 校验同城市同投保类型是否存在默认方案
export async function checkExistDefaultSchemeApi(params) {
  return request(`${INSURANCE}/scheme/checkExistDefaultScheme`, METHOD.GET, params)
}
// 校验同城市同投保类型是否存在默认方案
export async function checkChangSchemeApi(params) {
  return request(`${INSURANCE}/scheme/checkChangScheme`, METHOD.GET, params)
}

// 社保主体

// 查询参保主体列表
export async function getInsureCompanyListApi(params) {
  return request(`${INSURANCE}/insureCompany/getInsureCompanyList`, METHOD.GET, params)
}
// 新增参保主体
export async function createInsureCompanyApi(params) {
  return request(`${INSURANCE}/insureCompany/createInsureCompany`, METHOD.POST, params)
}
// 修改参保主体
export async function updateInsureCompany(params) {
  return request(`${INSURANCE}/insureCompany/updateInsureCompany`, METHOD.POST, params)
}
// 删除参保主体
export async function delInsureCompanyApi(params) {
  return request(`${INSURANCE}/insureCompany/delInsureCompany`, METHOD.POST, params)
}
// 获取参保城市
export async function getCityListApi(params) {
  return request(`${INSURANCE}/insureCompany/getCityList`, METHOD.GET, params)
}
// 根据城市获取参保主体
export async function getCompanyByCityApi(params) {
  return request(`${INSURANCE}/insureCompany/getCompanyByCity`, METHOD.GET, params)
}
// 设置默认参保主体
export async function setupDefaultValueApi(params) {
  return request(`${INSURANCE}/insureCompany/setupDefaultValue`, METHOD.POST, params)
}

// 消息提醒

// 获取消息提醒列表
export async function getRemindListApi(params) {
  return request(`${INSURANCE}/remind/getRemindList`, METHOD.GET, params)
}
// 新增消息提醒
export async function createRemindApi(params) {
  return request(`${INSURANCE}/remind/createRemind`, METHOD.POST, params)
}
// 编辑消息提醒
export async function updateRemindApi(params) {
  return request(`${INSURANCE}/remind/updateRemind`, METHOD.POST, params)
}
// 删除消息提醒
export async function deleteRemindApi(params) {
  return request(`${INSURANCE}/remind/deleteRemind`, METHOD.POST, params)
}
// 更新消息提醒状态
export async function changeRemindStatusApi(params) {
  return request(`${INSURANCE}/remind/changeRemindStatus`, METHOD.POST, params)
}
// 获取消息提醒信息（点击编辑时使用）
export async function getRemindInfoApi(params) {
  return request(`${INSURANCE}/remind/getRemindInfo`, METHOD.GET, params)
}
